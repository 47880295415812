import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class Service {

  apiBaseURL = `${environment.apiBaseURL}`;
  webAPIBaseURL = `${environment.webAPIBaseURL}`;
  stripeBaseURL = `${environment.stripeBaseURL}`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'    
    })
  };

  constructor(public http: HttpClient) { }

  getHighlights() {
    return this.http.get(this.apiBaseURL + "highlights", this.httpOptions);
  }

  getGallery() {
    return this.http.get(this.apiBaseURL + "gallery", this.httpOptions);
  }

  getEvents() {
    return this.http.get(this.apiBaseURL + "events", this.httpOptions);
  }

  getBanners() {
    return this.http.get(this.apiBaseURL + "banners", this.httpOptions);
  }

  getMembers() {
    return this.http.get(this.apiBaseURL + "members", this.httpOptions);
  }

  validatePostcode(postcode: any){
    var url = "https://api.postcodes.io/postcodes/" + postcode + "/validate";
    return this.http.get(url,this.httpOptions);
  }

  getAddressList(postcode: any){    
    return this.http.get(this.webAPIBaseURL + "/address?postcode=" + postcode, this.httpOptions);
  }

  getAddress(obj: any){    
    return this.http.post<any>(this.webAPIBaseURL + "/address", obj, this.httpOptions);
  }

  getMembersForMap() {
    return this.http.get(this.webAPIBaseURL + "/members", this.httpOptions);
  }

  postcodeLookup(postcode: any) {
    var url = 'https://api.postcodes.io/postcodes/' + postcode;
    return this.http.get(url,this.httpOptions);
  }

  registerMembers(obj: any) {    
    return this.http.post<any>(`${environment.registerAPIUrl}`, obj, this.httpOptions);
  }

  // Function to get Stripe Session
  createSession(data: any) {
    return this.http.post(this.stripeBaseURL + "/createSession", data, this.httpOptions);
  }

  retrieveSession(obj: any) {    
    return this.http.post(this.stripeBaseURL + "/retrieveSession", obj, this.httpOptions);
  }

  sendMessage(data: any) {
    return this.http.post<any>(`${environment.sendMessageAPIUrl}`, data, this.httpOptions);
  }
}