<div class="container mt-4 mb-4">
    <div class="row mt-4">
        <div class="col-md-3"></div>
        <div class="col-md-6 mt-4 mb-4 bg-white register">
            <h1 class="mt-3 mb-2">Register</h1>
            <hr />
            <h2>Your Details:</h2><br />
            <form [formGroup]="mainApplicantForm">
                <div class="form-row">
                    <div class="form-group col-6">
                        <input type="text" formControlName="firstName" class="form-control"                            
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="First Name*" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">Please enter First Name.</div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <input type="text" formControlName="lastName" class="form-control"                            
                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Last Name*" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Please enter Last Name.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email*" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Please enter Email address.</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-5">
                        <input type="number" formControlName="phone" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Mobile Number*" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">Please enter Mobile number.</div>
                        </div>
                    </div>
                    <div class="form-group form-check col-1"></div>
                    <div class="form-group form-check col-6">
                        <input type="checkbox" formControlName="whatsApp" id="whatsApp" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.whatsApp.errors }" />
                        <label for="whatsApp" class="form-check-label">I give consent to add in UWAUK Parivar whatsApp group.</label>                        
                    </div>                                       
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <div class="radio-inline">
                            <label> Gender* : </label>&nbsp;&nbsp;
                            <input id="gender" type="radio" name="gender" value="Male" formControlName="gender"
                                (change)="getGender($event.target.value)" />&nbsp;
                            <label class="radio-label">Male</label>&nbsp;&nbsp;
                            <input id="gender" type="radio" name="gender" value="Female" formControlName="gender"
                                (change)="getGender($event.target.value)" />&nbsp;
                            <label class="radio-label">Female</label>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label>Date of Birth* : </label>
                    </div>
                    <div class="form-group col-6">
                        <input type="date" class="form-control" formControlName="dob"
                            [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" />
                        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                            <div *ngIf="f.dob.errors.required">Date of Birth is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <input type="text" formControlName="houseNumber" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.houseNumber.errors }" placeholder="Flat/House Number*" />
                        <div *ngIf="submitted && f.houseNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.houseNumber.errors.required">Please enter Flat/House Number to get the address.</div>
                        </div>
                        <div *ngIf="isNoHouseNumber" class="text-danger small">Please enter Flat/House Number to get the address.</div>
                    </div>
                    <div class="form-group col-4">
                        <input type="text" formControlName="postcode" class="form-control"
                            (change)="validatePostcode($event.target.value);" value="{{postcode}}"
                            [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }" placeholder="Postcode*" />
                        <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                            <div *ngIf="f.postcode.errors.required">Postcode is required</div>
                        </div>
                        <div *ngIf="isInvalid" class="text-danger small">Invalid Postcode.</div>
                    </div>
                    <div class="form-group col-4">                        
                        <button class="btn btn-primary btn-block" (click)="getAddress()" [disabled]="isInvalid">Find Address</button>
                    </div>
                </div>
                <div class="form-row" *ngIf="postcode != '' && !isInvalid">
                    <div class="form-group col">
                        <select class="form-control" formControlName="address" placeholder="Select Address"
                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                            <option [disabled]="true">--Select Address--</option>
                            <option [disabled]="true" *ngIf="addressNotFound">Address Not Found</option>
                            <option [value]="address" *ngFor="let address of addressList;">{{address}}</option>
                        </select>
                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                            <div *ngIf="f.address.errors.required">Please select your Address.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <input type="text" formControlName="occupation" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.occupation.errors }" placeholder="Occupation*" />
                        <div *ngIf="submitted && f.occupation.errors" class="invalid-feedback">
                            <div *ngIf="f.occupation.errors.required">Please enter your Occupation.</div>
                        </div>
                    </div>
                </div>
                <hr />
                <h2>Your Background from India:</h2><br />
                <div class="form-row">
                    <div class="form-group col">
                        <input type="text" formControlName="town" class="form-control" placeholder="Town / Village" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <input type="text" formControlName="district" class="form-control" placeholder="District" />
                    </div>
                </div>
                <hr />
                <h2>Marital Status:</h2><br />
                <div class="form-row">
                    <div class="form-group col">
                        <select class="form-control" formControlName="maritalStatus" placeholder="Marital Status*"
                            (change)="getMaritalStatus($event.target.value)"
                            [ngClass]="{ 'is-invalid': submitted && f.maritalStatus.errors }">
                            <option [value]="'Married'">Married</option>
                            <option [value]="'Unmarried'">Unmarried</option>
                            <option [value]="'Divorced'">Divorced</option>
                            <option *ngIf="gender === 'Male'" [value]="'Widower'">Widower</option>
                            <option *ngIf="gender === 'Female'" [value]="'Widow'">Widow</option>
                        </select>
                        <div *ngIf="submitted && f.maritalStatus.errors" class="invalid-feedback">
                            <div *ngIf="f.maritalStatus.errors.required">Please select your Marital Status from list.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row" *ngIf="maritalStatus != '' && maritalStatus === 'Married'">
                    <div class="form-group col">
                        <label>Marriage Date* : </label>
                    </div>
                    <div class="form-group col-6">
                        <input type="date" class="form-control" formControlName="anniversary"
                            [ngClass]="{ 'is-invalid': submitted && f.anniversary.errors }" />
                        <div *ngIf="submitted && f.anniversary.errors" class="invalid-feedback">
                            <div *ngIf="f.anniversary.errors.required">Please enter your Marriage Date.</div>
                        </div>
                    </div>
                </div>                
                <form [formGroup]="spouseForm">
                    <div *ngIf="maritalStatus != '' && maritalStatus === 'Married'">
                        <hr />
                        <h2>Spouse Details:</h2><br />
                        <div class="form-row">
                            <div class="form-group col-6">
                                <input type="text" formControlName="firstName" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && fs.firstName.errors }"
                                    placeholder="First Name*" />
                                <div *ngIf="submitted && fs.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="fs.firstName.errors.required">Please enter First Name.</div>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <input type="text" formControlName="lastName" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && fs.lastName.errors }"
                                    placeholder="Last Name*" />
                                <div *ngIf="submitted && fs.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="fs.lastName.errors.required">Please enter Last Name.</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <input type="text" formControlName="email" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && fs.email.errors }" placeholder="Email*" />
                                <div *ngIf="submitted && fs.email.errors" class="invalid-feedback">
                                    <div *ngIf="fs.email.errors.required">Please enter Email address.</div>
                                    <div *ngIf="fs.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-5">
                                <input type="number" formControlName="phone" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && fs.phone.errors }"
                                    placeholder="Mobile Number*" />
                                <div *ngIf="submitted && fs.phone.errors" class="invalid-feedback">
                                    <div *ngIf="fs.phone.errors.required">Please enter Mobile number.</div>
                                </div>
                            </div>
                            <div class="form-group form-check col-1"></div>
                            <div class="form-group form-check col-6">
                                <input type="checkbox" formControlName="whatsApp" id="whatsApp" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && fs.whatsApp.errors }" />
                                <label for="whatsApp" class="form-check-label">I give consent to add in UWAUK Parivar WhatsApp group.</label>                        
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <div class="radio-inline">
                                    <label> Gender* : </label>&nbsp;&nbsp;
                                    <input id="spousegender" type="radio" name="spousegender" value="Male"
                                        formControlName="spousegender" />&nbsp;
                                    <label class="radio-label">Male</label>&nbsp;&nbsp;
                                    <input id="spousegender" type="radio" name="spousegender" value="Female"
                                        formControlName="spousegender" />&nbsp;
                                    <label class="radio-label">Female</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label>Date of Birth* : </label>
                            </div>
                            <div class="form-group col-6">
                                <input type="date" class="form-control" formControlName="dob"
                                    [ngClass]="{ 'is-invalid': submitted && fs.dob.errors }" />
                                <div *ngIf="submitted && fs.dob.errors" class="invalid-feedback">
                                    <div *ngIf="fs.dob.errors.required">Date of Birth is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <input type="text" formControlName="occupation" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.occupation.errors }"
                                    placeholder="Occupation*" />
                                <div *ngIf="submitted && f.occupation.errors" class="invalid-feedback">
                                    <div *ngIf="f.occupation.errors.required">Please enter your Occupation.</div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h2>Your Spouse Background from India:</h2><br />
                        <div class="form-row">
                            <div class="form-group col">
                                <input type="text" formControlName="town" class="form-control"
                                    placeholder="Town / Village" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <input type="text" formControlName="district" class="form-control"
                                    placeholder="District" />
                            </div>
                        </div>
                    </div>
                </form>
                <!-- <div class="form-row" *ngIf="maritalStatus != '' && maritalStatus === 'Married'">
                    <div class="form-group col">
                        <div class="radio-inline">
                            <label> Do you want to Register your Spouse? : </label>&nbsp;&nbsp;
                            <input id="isSpouse" type="radio" name="isSpouse" value="Yes" formControlName="isSpouse"
                                (change)="spouseYesNo($event.target.value)" />&nbsp;
                            <label class="radio-label">Yes</label>&nbsp;&nbsp;
                            <input id="isSpouse" type="radio" name="isSpouse" value="No" formControlName="isSpouse"
                                (change)="spouseYesNo($event.target.value)" />&nbsp;
                            <label class="radio-label">No</label>
                        </div>
                    </div>
                </div> -->
                <div class="form-row" *ngIf="isMarried">
                    <div class="form-group col">
                        <div class="radio-inline">
                            <label> Do you have child/children? </label>&nbsp;&nbsp;
                            <input id="isChild" type="radio" name="isChild" value="Yes" formControlName="isChild"
                                (change)="childYesNo($event.target.value)" />&nbsp;
                            <label class="radio-label">Yes</label>&nbsp;&nbsp;
                            <input id="isChild" type="radio" name="isChild" value="No" formControlName="isChild"
                                (change)="childYesNo($event.target.value)" />&nbsp;
                            <label class="radio-label">No</label>
                        </div>
                    </div>                    
                    <div class="form-group col-2">
                        <button style="color: green;" type="button" title="Add Child Details" class="btn btn-lg"
                            (click)="addChild()" [hidden]="!isChild"><i class="fa fa-user-plus"></i></button>
                    </div>
                </div>
                <form [formGroup]="childrenForm">
                    <div *ngIf="isChild" formArrayName="children">
                        <div *ngFor="let child of children.controls; let i=index;" [formGroupName]="i">
                            <hr />
                            <div class="form-row">
                                <div class="form-group col">
                                    <h2>Child {{i+1}} Details:</h2><br />
                                </div>
                                <div class="form-group col-2">
                                    <button style="color: red;" type="button" title="Remove Child Details" class="btn btn-lg"
                                        (click)="removeChild(i)"><i class="fa fa-times"></i></button>
                                </div>                                
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <input type="text" formControlName="firstName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && fc.children.controls[i].get('firstName').errors }" placeholder="First Name*" />
                                    <div *ngIf="submitted && fc.children.controls[i].get('firstName').errors" class="invalid-feedback">
                                        <div *ngIf="fc.children.controls[i].get('firstName').errors.required">Please enter First Name.</div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <input type="text" formControlName="lastName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && children.controls[i].get('lastName')?.errors }" placeholder="Last Name*" />
                                    <div *ngIf="submitted && children.controls[i].get('lastName')?.errors" class="invalid-feedback">
                                        <div *ngIf="children.controls[i].get('lastName')?.errors?.required">Please enter Last Name.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <div class="radio-inline">
                                        <label> Gender* : </label>&nbsp;&nbsp;
                                        <input id="childgender" type="radio" value="Male" (change)="onChange($event.target.value)"
                                            formControlName="childgender" />&nbsp;
                                        <label class="radio-label">Male</label>&nbsp;&nbsp;
                                        <input id="childgender" type="radio" value="Female" (change)="onChange($event.target.value)"
                                            formControlName="childgender" />&nbsp;
                                        <label class="radio-label">Female</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label>Date of Birth* : </label>
                                </div>
                                <div class="form-group col-6">
                                    <input type="date" class="form-control" formControlName="dob"
                                        [ngClass]="{ 'is-invalid': submitted && fc.children.controls[i].get('dob').errors }" />
                                    <div *ngIf="submitted && fc.children.controls[i].get('dob').errors" class="invalid-feedback">
                                        <div *ngIf="fc.children.controls[i].get('dob').errors.required">Date of Birth is required</div>
                                    </div>
                                </div>
                            </div>  
                            <div class="form-row">
                                <div class="form-group col"></div>                                
                                <div class="form-group col-2">
                                    <button style="color: green;" type="button" title="Add Another Child Details" class="btn btn-lg"
                                        (click)="addChild()"><i class="fa fa-user-plus"></i></button>
                                </div>
                            </div>                    
                        </div>                        
                    </div>
                </form>
                <hr />
                
                <p style="text-align: justify;">Uttarakhand Welfare Association (UWAUK) is determind to abide by the Data Protection Law. All information is solely for the purpose of collecting data of Uttarakhandi residing in UK. We will not disclose of any information of our members, except if it is neccessary as per the law of country.</p>
                <div class="form-group form-check">
                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                    <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Please Accept Terms & Conditions</div>
                </div>
                <div class="form-group form-check">
                    <input type="checkbox" formControlName="declaration" id="declaration" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.declaration.errors }" />
                    <label for="declaration" class="form-check-label">I hearby confirm that all information is provided by me is correct.</label>
                    <div *ngIf="submitted && f.declaration.errors" class="invalid-feedback">Please give your declaration.</div>
                </div>
                <hr/>
                <div class="text-center">
                    <button class="btn btn-primary mr-1" (click)="register()">Register</button>
                </div>
                <br />
            </form>
        </div>
    </div>
</div>

<div class="container fblink">
    <p>We will appreciate your Contribution.<br>
      <a routerLink="/donate">Donate</a>
    </p>
</div>

<div class="modal fade" id="staticBackdrop"  data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Success</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h6>Thanks for joining UWAUK Community. You will get registration confirmation email soon.</h6>
        <h6 *ngIf="isChild && isAdult">If you child's age is over 18 years, he/she is eligible to register separately. Please ask him to register using his/her email address.</h6>
        <img style="height:110px; width:150px; " src="assets/images/namste.png" />
        <h6>We will appreciate your Contribution.</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="donate()">Donate</button>  
        <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</div>