import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CognitoAuthService {

  userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  public get userDetails(): any {
    return this.userSubject.value;
  }

  login() {
    location.href = `${environment.cognitoBaseURL}` + "login?client_id=" + `${environment.clientID}` + "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + `${environment.redirectURI}`;
  }

  requestTokens(code: any) {
    var url = `${environment.cognitoBaseURL}` + "oauth2/token?grant_type=authorization_code&client_id=" + `${environment.clientID}` + "&code=" + code + "&redirect_uri=" + `${environment.redirectURI}`;
    const clientIdEncoded = btoa(`${environment.clientID}:${environment.clientSecret}`);
    var httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${clientIdEncoded}`
      })
    };
    return this.http.post<any>(url, {}, httpHeaders);
  }

  requestUserInfo(accessToken: any) {
    var url = `${environment.cognitoBaseURL}` + "oauth2/userInfo";
    var httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${accessToken}`
      })
    };
    return this.http.post<any>(url, {}, httpHeaders);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('idToken');    
    //localStorage.clear();
    this.userSubject.next(null);
    location.href = `${environment.cognitoBaseURL}` + "logout?client_id=" + `${environment.clientID}` + "&logout_uri=" + `${environment.signOutURL}`;

  }
}
