<section>
    <div class="container cancel">
        <div class="row align-items-lg-center">
            <br/>
            <div class="col-12 col-md-2" data-aos="fade-left">
                <img class="img-fluid" src="assets/images/sorry.jpg" alt="">
            </div>
            <div class="col-12 col-md-8">
                <h1>There is some issue with your payment.</h1>
                <h2>Please be in touch with Admin if money has been deducted from your account.</h2>
            </div>
            <div class="col-12 col-md-2" data-aos="fade-left">
                <img class="img-fluid" src="assets/images/sorry.jpg" alt="">
            </div>
            <br/>
        </div>
    </div>
</section>