<div class="container-fluid about-us">
    <div class="text text-center">
      <h2 class=" animate__animated animate__zoomIn animate__delay-2s">Uttarakhand Welfare Association - UK</h2>
    </div>
  </div>
  
  <div class="card aboutus">
    <h1>About Us</h1>
  
    <p>Uttarakhand Welfare Association - UK has been a non-profit making organisation since 2010. UWAUK got registered as
      a charity organisation since November 2016.</p>
  
    <p>UWAUK members include people from the Indian state of Uttarakhand and their dependents living in the United
      Kingdom.
      People of Uttarakhand origin live in almost every part of United Kingdom. The majority of them live in cities
      including London, Birmingham, Nottingham, Leicester, Edinburgh & Glasgow.</p>
  
    <p>The charity was formed for the promotion of social inclusion for the public benefit for people originating from
      Uttarakhand, in particular, but not exclusively, those that have recently arrived in the UK who are socially
      excluded on the grounds of their social and economic position and need support to survive in the society.</p>
  </div>
  
  <div class="card aboutus">
    <h1>Where we work</h1>
  
    <p>United Kingdom is the main working area of the charity. However, as the terrain of Uttarakhand region in India is
      prone to extreme rainfall, earthquake, landslides, flooding – resulting into calamities and hardship to the local
      people who are already impoverished, assistance is provided there as well. Loss of life and of cattle (vital to the
      villagers’ livelihood) routinely occurs. Even after relief work carried out by local government agencies the
      infra-structure remains rudimentary.</p>
  
    <p>Our charity team establishes contacts with the local relief organisations when our help may be required and then
      members from UK will visit the area where the natural disaster has occurred and provide support to the local people
      by way of providing financial assistance, food, medicines and clothes.</p>
  </div>
  
  <div class="card aboutus">
    <h1>Our Charity</h1>
  
    <p>The charity has a group of dedicated volunteers, specialists in their respective fields who volunteer their
      services. The help is provided on a one-to-one basis or in a group, if appropriate, at hired venues or at a
      volunteer’s home. Guidance is principally given in tutoring of English but also providing additional educational
      help. There is also a significant requirement for assistance in addressing employment opportunities which volunteers
      do help to meet according to their expertise. The purpose of these aims is to make members and non-members who seek
      our help financially independent, less reliant on other benefits and socially stable.</p>
  
    <p>We aim to communicate with beneficiaries on a regular basis by telephone, social media and personal visits, by
      inclusion at educational and social events. We also hold periodic meetings where members can raise issues which
      affect them and at which the charity can help to address the issues.</p>
  
    <p>Membership of the charity is continually changing so there is an on-going need for communication and help. The
      charity organises public events including the Uttarakhand mela (fair), Family days out, Holi festival and Diwali
      festival which involves the local community and public. These functions promote Uttarakhand culture and cultural
      conservation – most importantly communication in Garhwali and Kumaoni to keep the languages alive. Events including
      the Uttarakhand dress competition, singing pahadi songs, traditional dancing and serving traditional food during our
      Diwali and Holi cultural events is widely appreciated.</p>
  </div>
  
  <div class="card aboutus">
    <h1>Recent notable activities</h1>
  
    <p>During Kedarnath Flooding disaster in 2013, UWAUK raised money for the flood victims and our volunteers distributed
      relief material in Rudraprayag Uttarakhand. We also accept donations for such disaster and other charity works.</p>
  
    <p>Recently we established association with two schools in the hills of Uttarakhand. Our charity will provide
      monetary, Educational & technical support to Shri Timli Vidhyapeeth (Pauri Garhwal) and Motherland Public School
      (Bageshwar - Kumaon Region).</p>
  
    <p>UWAUK representatives attended Uttarakhand Tourism Evening event organised by Uttarakhand Tourism Ministry to
      explore any new possibilities for tie up with the Government of Uttarakhand. As UWAUK members are located all over
      United kingdom so this can be a win win situation for both UWAUK and Government of Uttarakhand. The High Commission
      of India have also added us to their contact list so that we are invited to all Cultural programmes held from time
      to time.</p>
  </div>