import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CmsService {

  apiBaseURL = `${environment.apiBaseURL}`;
  apiForS3URL = `${environment.apiForS3URL}`;
  idToken = localStorage.getItem('idToken');   

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })    
  };

  httpS3 = {
    headers: new HttpHeaders({
      'Content-Type': 'image/*' 
    })
  };

  constructor(public http: HttpClient) { }

  addHighlight(obj: any) {    
    //console.log('ID Token: ', this.idToken);     
    return this.http.post<any>(this.apiBaseURL + "highlight/add", obj, this.httpOptions);
  }

  deleteHighlight(obj: any) {
    return this.http.post<any>( this.apiBaseURL + "highlight/delete", obj, this.httpOptions);
  }

  addGallery(obj: any) {
    return this.http.post<any>(this.apiBaseURL + "gallery/add", obj, this.httpOptions);
  }

  deleteGallery(obj: any) {
    return this.http.post<any>( this.apiBaseURL + "gallery/delete", obj, this.httpOptions);
  }

  addEvent(obj: any) {
    return this.http.post<any>(this.apiBaseURL + "events/add", obj, this.httpOptions);
  }

  deleteEvent(obj: any) {
    return this.http.post<any>( this.apiBaseURL + "events/delete", obj, this.httpOptions);
  }

  addBanner(obj: any) {
    return this.http.post<any>(this.apiBaseURL + "banner/add", obj, this.httpOptions);
  }

  deleteBanner(obj: any) {
    return this.http.post<any>( this.apiBaseURL + "banner/delete", obj, this.httpOptions);
  }

  uploadImage(imageId: String, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.put(`${this.apiForS3URL}${imageId}`, formData, this.httpS3);
  }

  deleteImage(imageId: String): Observable<any> {        
    return this.http.delete(`${this.apiForS3URL}${imageId}`);
  }
}
