<div class="container-fluid">
    <h1>See Registered UWAUK Members on Map</h1>
    <h2>Registered Members: {{membersCount}}</h2>  
    <div class="row">
        <div class="col-md-12">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [disableDefaultUI]="false" (mapClick)="mapClicked($event)">
                <agm-marker *ngFor="let member of members;" (markerClick)="clickedMarker(member.label)"
                    [latitude]="member.latitude" [longitude]="member.longitude" [label]="member.label" [markerDraggable]="false">

                    <agm-info-window>
                        <strong>{{member.nameDisplay}}</strong>
                    </agm-info-window>

                </agm-marker>
            </agm-map>
        </div>  
    </div>
  </div>