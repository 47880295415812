import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  s3BaseURL = `${environment.s3BaseUrl}`; 

  management = [{
      name: "Manish Jugran",
      position: "Chairman",
      image: this.s3BaseURL + "ManishJugran.jpg"
    }, {
      name: "Avtar Aswal",
      position: "Secretary",
      image: this.s3BaseURL + "AvtarAswal.jpg"
    }, {
      name: "Jitendra Singh Rana",
      position: "Treasurer",
      image: this.s3BaseURL + "JitendraSinghRana.jpg"
    }, {
      name: "Krishnapal Negi",
      position: "Director",
      image: this.s3BaseURL + "KrishnapalNegi.jpg"
    }, {
      name: "Prem Singh Patwal",
      position: "Director",
      image: this.s3BaseURL + "PremSinghPatwal.jpg"
    }, {
      name: "Ramvir Singh Panwar",
      position: "Director",
      image: this.s3BaseURL + "RamvirSinghPanwar.jpg"
    }, {
      name: "Saukar Rawat",
      position: "Director",
      image: this.s3BaseURL + "SaukarRawat.jpg"
    }
  ];
  
  coreMembers = [{
      name: "Amit Payal",
      image: this.s3BaseURL + "AmitPayal.jpg"
    },{
      name: "Hukum Rawat",
      image: this.s3BaseURL + "HukumRawat.jpg"
    },{
      name: "Manoj Pant",
      image: this.s3BaseURL + "ManojPant.jpg"
    },{
      name: "Pawan Ghildiyal",
      image: this.s3BaseURL + "PawanGhildiyal.jpg"
    },{
      name: "Priyanka Rawat",
      image: this.s3BaseURL + "PriyankaRawat.jpg"
    },{
      name: "Shiv Singh Jagwan",
      image: this.s3BaseURL + "ShivSinghJagwan.jpg"
    },{
      name: "Surat Rana",
      image: this.s3BaseURL + "SuratRana.jpg"
    }
  ];
  
  preChairmans = [{
    name: "Laxmikant Joshi",
    image: this.s3BaseURL + "LaxmikantJoshi.jpg",
    tenuareStart: "2020",
    tenuareEnd: "2021"
  },{
    name: "Diwan Singh Bhandari",
    image: this.s3BaseURL + "DiwanSinghBhandari.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Krishnapal Negi",
    image: this.s3BaseURL + "KrishnapalNegi.jpg",
    tenuareStart: "2013",
    tenuareEnd: "2016"
  }];

  preSecratories = [{
    name: "Pankaj Nautiyal",
    image: this.s3BaseURL + "PankajNautiyal.jpg",
    tenuareStart: "2020",
    tenuareEnd: "2022"
  },{
    name: "Birender Rawat",
    image: this.s3BaseURL + "BirenderRawat.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Saukar Rawat",
    image: this.s3BaseURL + "SaukarRawat.jpg",
    tenuareStart: "2015",
    tenuareEnd: "2016"
  }];

  preDirectors = [{
    name: "Sandeep Bisht",
    image: this.s3BaseURL + "SandeepBisht.jpg",
    tenuareStart: "2022",
    tenuareEnd: "2023"
  },{
    name: "Pankaj Nautiyal",
    image: this.s3BaseURL + "PankajNautiyal.jpg",
    tenuareStart: "2020",
    tenuareEnd: "2022"
  },{
    name: "Naveen Sharma",
    image: this.s3BaseURL + "NaveenSharma.jpg",
    tenuareStart: "2021",
    tenuareEnd: "2022"
  },{
    name: "Laxmikant Joshi",
    image: this.s3BaseURL + "LaxmikantJoshi.jpg",
    tenuareStart: "2010",
    tenuareEnd: "2021"
  },{
    name: "Diwan Singh Bhandari",
    image: this.s3BaseURL + "DiwanSinghBhandari.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Birender Rawat",
    image: this.s3BaseURL + "BirenderRawat.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Shiv Singh Jagwan",
    image: this.s3BaseURL + "ShivSinghJagwan.jpg",
    tenuareStart: "2018",
    tenuareEnd: "2020"
  },{
    name: "Pushkar Rawat",
    image: this.s3BaseURL + "PushkarRawat.jpg",
    tenuareStart: "2018",
    tenuareEnd: "2020"
  },{
    name: "Krishnapal Negi",
    image: this.s3BaseURL + "KrishnapalNegi.jpg",
    tenuareStart: "2013",
    tenuareEnd: "2016"
  },{
    name: "Ramvir Singh Panwar",
    image: this.s3BaseURL + "RamvirSinghPanwar.jpg",
    tenuareStart: "2015",
    tenuareEnd: "2016"
  },{
    name: "Rajender Singh Rawat",
    image: this.s3BaseURL + "man.jpg",
    tenuareStart: "2010",
    tenuareEnd: "2015"
  },{
    name: "Narendra Singh",
    image: this.s3BaseURL + "man.jpg",
    tenuareStart: "2010",
    tenuareEnd: "2012"
  }];

  constructor() { }

  ngOnInit(): void {
    // Fetch the members details from the backend 
    // Seperate them as a Chairman, Secratery, Core Members, Pre Chairmans & Pre Directors

  }
}