import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uttrakhand',
  templateUrl: './uttrakhand.component.html',
  styleUrls: ['./uttrakhand.component.scss']
})
export class UttrakhandComponent implements OnInit {

  s3BaseURL = `${environment.s3BaseUrl}`; 

  constructor() { }

  ngOnInit(): void {}

  public gallery = [
    this.s3BaseURL + "gallery-01.jpeg",    
    this.s3BaseURL + "gallery-03.jpeg",
    this.s3BaseURL + "gallery-04.jpeg",
    this.s3BaseURL + "gallery-05.jpeg",
    this.s3BaseURL + "gallery-11.jpeg",
    this.s3BaseURL + "gallery-12.jpeg",
    this.s3BaseURL + "gallery-13.jpeg",
    this.s3BaseURL + "gallery-14.jpeg",
    this.s3BaseURL + "gallery-15.jpeg",
  ];
}