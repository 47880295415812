import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit {

  events = [{
    title: "AGM + Pre Holi Catchup",
    eventDate: "Sun, 03 Mar 2024",
    eventTime: "14:00 - 17:00",
    venue: "Langley Pavilion Hall (Onsite)",
    comments: "Provision for Tea, Snacks, Lunch"
  }, {
    title: "Holi Event",
    eventDate: "Sun, 17 Mar 2024",
    eventTime: "11:00 - 17:00",
    venue: "Blueroom, Harrow (Onsite)",
    comments: "TBC by event team Ticket : £20(Adult) , £15(Child)"
  }, {
    title: "Core-group meeting",
    eventDate: "Sun, 31 Mar 2024",
    eventTime: "21:00 - 22:00",
    venue: "Online/Zoom",
    comments: ""
  }, {
    title: "Core-group meeting",
    eventDate: "Sun, 02 Jun 2024",
    eventTime: "14:00 - 17:00",
    venue: "Reading/Langley (TBC) (Onsite)",
    comments: "Share details in Parivar group"
  }, {
    title: "Summer Event",
    eventDate: "Weather dependant",
    eventTime: "",
    venue: "Not yet confirmed",
    comments: "More – Offline discussion"
  }, {
    title: "Pre-Dipawali Catchup",
    eventDate: "Sun, 15 Sept 2024",
    eventTime: "14:00 - 17:00",
    venue: "Langley (TBC) (Onsite)",
    comments: ""
  }, {
    title: "Post-Dipawali Catchup",
    eventDate: "Sun, 10 Nov 2024",
    eventTime: "21:00 - 22:00",
    venue: "Online/Zoom",
    comments: "Accounts presentation"
  }];

  constructor() { }

  ngOnInit(): void {
  }

}
