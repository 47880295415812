<nav class="navbar navbar-expand-md navbar-light fixed-top for-mobile">
  <a class="navbar-brand"  (click)="navigateUrl('/')"><img height="70" width="70" src="assets/images/uwauk-logo.jpg"></a>

  <button type="button" id="nav-mbl-head" style="float: right !important;margin-top: 15px;"    
    class="navbar-toggler bg-light" data-toggle="collapse" data-target="#nav">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-between" id="nav">
    <ul class="navbar-nav text-center">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/'" (click)="navigateToUrl('/')">Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/about-us'" (click)="navigateToUrl('/about-us')">About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/members'" (click)="navigateToUrl('members')">Members </a>        
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/uwauk-on-map'" (click)="navigateToUrl('/uwauk-on-map')">On Map </a>
      </li>      
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/uttrakhand'" (click)="navigateToUrl('/uttrakhand')">Uttarakhand&nbsp;</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/gallery'" (click)="navigateToUrl('/gallery')">Gallery&nbsp;</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateToUrl('/contact-us')">Contact Us&nbsp;</a>
      </li>        
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/register'" (click)="navigateToUrl('/register')">Join UWAUK</a>
      </li>    
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/donate'" (click)="navigateToUrl('/donate')">Donate</a>
      </li>  
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/event-calendar'" (click)="navigateToUrl('/event-calendar')">Event Calendar</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/book-tickets'" (click)="navigateToUrl('/book-tickets')">Event Tickets</a>
      </li> 
    </ul>
  </div>
</nav>

<nav class="navbar navbar-expand-lg  fixed-top show-for-web">

  <!--  Show this only on mobile to medium screens  -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggle"
    aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!--  Use flexbox utility classes to change how the child elements are justified  -->
  <div class="collapse navbar-collapse justify-content-between" style="padding-left: 30px;padding-right: 30px;"
    id="navbarToggle"> 
    <a *ngIf="showHideMenu" class="navbar-brand d-none d-lg-block" (click)="navigateToUrl('/')">
      <img class="header-logo" src="assets/images/uwauk-logo.jpg" />
    </a>
    <ul class="navbar-nav" *ngIf="showHideMenu">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/about-us'" (click)="navigateToUrl('/about-us')">About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/members'" (click)="navigateToUrl('/members')">Members </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/uwauk-on-map'" (click)="navigateToUrl('/uwauk-on-map')">On Map </a>
      </li>      
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/uttrakhand'" (click)="navigateToUrl('/uttrakhand')">Uttarakhand</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/gallery'" (click)="navigateToUrl('/gallery')">Gallery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateToUrl('/contact-us')">Contact Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/register'" (click)="navigateToUrl('/register')">Join UWAUK</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/event-calendar'" (click)="navigateToUrl('/event-calendar')">Event Calendar</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/book-tickets'" (click)="navigateToUrl('/book-tickets')">Event Tickets</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/donate'" (click)="navigateToUrl('/donate')">Donate</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="login()">Login </a>
      </li>
    </ul>

    <ul class="navbar-nav min-menu" *ngIf="!showHideMenu">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/'" (click)="navigateToUrl('/')">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item ml-2">
        <a class="nav-link" [class.nav-active]="currentUrl === '/members'" (click)="navigateToUrl('/members')"> Members</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/uwauk-on-map'" (click)="navigateToUrl('/uwauk-on-map')">On Map </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/gallery'" (click)="navigateToUrl('/gallery')"> Gallery</a>
      </li>
      <li class="nav-item ml-2">
        <a class="nav-link" [class.nav-active]="currentUrl === '/uttrakhand'" (click)="navigateToUrl('/uttrakhand')">Uttarakhand</a>
      </li>
    </ul>

    <!--   Show this only lg screens and up   -->
    <a *ngIf="!showHideMenu" class="navbar-brand d-none d-lg-block" (click)="navigateToUrl('/')">
      <img class="header-logo" src="assets/images/uwauk-logo.jpg" />
    </a>

    <ul class="navbar-nav" *ngIf="!showHideMenu">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/event-calendar'" (click)="navigateToUrl('/event-calendar')">Event Calendar</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/book-tickets'" (click)="navigateToUrl('/book-tickets')">Event Tickets</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/register'" (click)="navigateToUrl('/register')"> Join UWAUK</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateToUrl('/contact-us')"> Contact Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/donate'" (click)="navigateToUrl('/donate')">Donate</a>
      </li>      
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="toggleMenu()"><i class="fa fa-bars" aria-hidden="true"></i></a>
      </li>
    </ul>
  </div> 
</nav>

<div class="container-fluid marque">
  <div class="row">
    <div class="col-md-12">
      <marquee class="marq" id="mark" style=" margin:5px; color: black; font-size: 22px; font-weight: 500;" direction="left" loop="">
        {{ message }}
      </marquee>
    </div>
  </div>
</div>