<div class="container-fluid">
    <h1>Core Committee</h1>    
    <div class="row">        
        <div class="col-md-3"></div>
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[0].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[0].name}}<br/>
                        {{management[0].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[1].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[1].name}}<br/>
                        {{management[1].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[2].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[2].name}}<br/>
                        {{management[2].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-3"></div>        
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[3].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[3].name}}<br/>
                        {{management[3].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>              
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[4].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[4].name}}<br/>
                        {{management[4].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>   
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[5].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[5].name}}<br/>
                        {{management[5].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-2">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[6].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[6].name}}<br/>
                        {{management[6].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>     
        <div class="col-md-2"></div>             
    </div>
    <div class="row">
        <div class="col-md-2" *ngFor="let member of coreMembers;">
            <div class="card">
                <div class="card-body">
                    <img src="{{member.image}}" class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{member.name}}<br/>
                        Core Member
                    </h4> 
                </div>
            </div>
        </div>
    </div>
</div>
<hr/>
<div class="container-fluid">
    <h2>Previous Chairmans</h2>
    <div class="row">
        <div class="col-md-2" *ngFor="let member of preChairmans;">
            <div class="card">
                <div class="card-body">
                    <img src="{{member.image}}" class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{member.name}}<br/>
                        ({{member.tenuareStart}} - {{member.tenuareEnd}})
                    </h4> 
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <h2>Previous Secretaries</h2>
    <div class="row">
        <div class="col-md-2" *ngFor="let member of preSecratories;">
            <div class="card">
                <div class="card-body">
                    <img src="{{member.image}}" class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{member.name}}<br/>
                        ({{member.tenuareStart}} - {{member.tenuareEnd}})
                    </h4> 
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <h2>Previous Directors</h2>
    <div class="row">
        <div class="col-md-2" *ngFor="let member of preDirectors;">
            <div class="card">
                <div class="card-body">
                    <img src="{{member.image}}" class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{member.name}}<br/>
                        ({{member.tenuareStart}} - {{member.tenuareEnd}})
                    </h4> 
                </div>
            </div>
        </div>
    </div>
</div>