import { Component, OnInit } from '@angular/core';
import { Service } from '../../services/uwauk.service';
//import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {
  // google maps zoom level
  zoom = 7;
  hideHeader = true;

  // initial center position for the map
  latitude = 51.709465; 
  longitude = -0.374774;

  postcodeCount = 0;
  membersCount = 0;
  members = [];
  onMap = 0;

  constructor(private service: Service,) { }

  ngOnInit(): void {
    this.fetchMembers();
  }

  fetchMembers() {
    this.service.getMembersForMap().subscribe((response: any) => {
      //console.log("Response: ", response);
      if (response.statusCode === 200) {
        var data = JSON.parse(response.body);
        this.postcodeCount = data.postcodeCount;
        this.membersCount = data.membersCount;
        //console.log("Number Of Postcodes: ", this.postcodeCount);
        this.members = data.members;
        //console.log("All Members: ", this.members);
        this.members.forEach(member => {
          var postcode = member.postcode.trim().toUpperCase();
          member.label = member.membersCountOnPostcode.toString();
          member.nameDisplay = "";
          member.membersOnPostcode.forEach(obj => {
            member.nameDisplay += obj.firstName;
            member.nameDisplay += " ";
            member.nameDisplay += obj.lastName;
            member.nameDisplay += " Ji & ";
          });
          member.nameDisplay = member.nameDisplay.substring(0, member.nameDisplay.length - 2);
          
          this.service.postcodeLookup(postcode).subscribe((res: any) => {
            if (res.status === 200) {              
              member.latitude = res.result.latitude;
              member.longitude = res.result.longitude;
            } else {
              //console.log("Error: ", res.error);
            }
            //console.log("Member Object: ", member);
          });
        });
        //console.log('On Map: ', this.onMap);
      } else {
        //console.log("Error fetching data");
      }
    });
  }

  mapClicked(event) {
    //console.log("Clicked on Map: ", event);
  }

  clickedMarker(label: string, index: number) {
    //console.log(`clicked the marker: ${label || index}`)
  }
}
