<section *ngIf="isActive">
  <div class="container-fluid" style="text-align: center; background-color: rgb(141,41,41);">
    <img height="50vh" class="img-fluid" src={{eventBanner}} alt="">
  </div>
</section><br/>

<section id="banner" class="banner" style="padding-bottom: 10px !important;">
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide"
      tyle="border: 1px solid #e6e4e4; border-radius: 10px; box-shadow: 0 0 5px #dcd9d9;">
      <img src="{{ slide.img }}" alt="" width="100%">
    </div>
  </ngx-slick-carousel>
</section>

<section class="paragraph" id="section-1">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-right">
        <h1>About Us</h1>
        <p>Uttarakhand Welfare Association - UK has been a non-profit making organisation since 2010. UWAUK got
          registered as a charity organisation since November 2016.</p>

        <p>UWAUK members include people from the Indian state of Uttarakhand and their dependents living in the United
          Kingdom. People of Uttarakhand origin live in almost every part of United Kingdom. The majority of them live
          in cities
          including London, Birmingham, Nottingham, Leicester, Edinburgh & Glasgow.</p>

        <p>The charity was formed for the promotion of social inclusion for the public benefit for people originating
          from Uttarakhand, in particular, but not exclusively, those that have recently arrived in the UK who are
          socially
          excluded on the grounds of their social and economic position and need support to survive in the society.</p>
      </div>
      <div class="col-12 col-md-6" data-aos="fade-left">
        <img class="img-fluid" src={{home1}} alt="">
      </div>
    </div>
  </div>
</section>

<section class="paragraph" id="section-2">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6" data-aos="fade-right">
        <img class="img-fluid" src={{home2}} alt="">
      </div>
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-left">
        <h1 style="font-family: 'alexbrush_reg',sans-serif;">Where We Work</h1>

        <p>United Kingdom is the main working area of the charity. However, as the terrain of Uttarakhand region in
          India is prone to extreme rainfall, earthquake, landslides, flooding – resulting into calamities and hardship
          to the
          local people who are already impoverished, assistance is provided there as well. Loss of life and of cattle
          (vital
          to the villagers’ livelihood) routinely occurs. Even after relief work carried out by local government
          agencies the
          infra-structure remains rudimentary.</p>

        <p>Our charity team establishes contacts with the local relief organisations when our help may be required and
          then members from UK will visit the area where the natural disaster has occurred and provide support to the
          local
          people by way of providing financial assistance, food, medicines and clothes.</p>
      </div>
    </div>
  </div>
</section>

<section class="paragraph" id="section-3">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-right">
        <h1 style="font-family: 'alexbrush_reg',sans-serif;">Our Charity</h1>

        <p>The charity has a group of dedicated volunteers, specialists in their respective fields who volunteer their
          services. The help is provided on a one-to-one basis or in a group, if appropriate, at hired venues or at a
          volunteer’s home. Guidance is principally given in tutoring of English but also providing additional
          educational help. There is also a significant requirement for assistance in addressing employment
          opportunities which
          volunteers do help to meet according to their expertise. The purpose of these aims is to make members and
          non-members who
          seek our help financially independent, less reliant on other benefits and socially stable.</p>

        <p>We aim to communicate with beneficiaries on a regular basis by telephone, social media and personal visits,
          by inclusion at educational and social events. We also hold periodic meetings where members can raise issues
          which affect them and at which the charity can help to address the issues.</p>

        <p>Membership of the charity is continually changing so there is an on-going need for communication and help.
          The charity organises public events including the Uttarakhand mela (fair), Family days out, Holi festival and
          Diwali festival which involves the local community and public. These functions promote Uttarakhand culture and
          cultural conservation – most importantly communication in Garhwali and Kumaoni to keep the languages alive.
          Events
          including the Uttarakhand dress competition, singing pahadi songs, traditional dancing and serving traditional
          food
          during our Diwali and Holi cultural events is widely appreciated.</p>
      </div>
      <div class="col-12 col-md-6" data-aos="fade-left">
        <img class="img-fluid" src={{home3}} alt="">
      </div>
    </div>
  </div>
</section>

<section class="paragraph" id="section-4">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6" data-aos="fade-right">
        <img class="img-fluid" src={{home4}} alt="">
      </div>
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-left">
        <h1>Recent Notable Activities</h1>

        <p>During Kedarnath Flooding disaster in 2013, UWAUK raised money for the flood victims and our volunteers
          distributed relief material in Rudraprayag Uttarakhand. We also accept donations for such disaster and other
          charity works.</p>

        <p>Recently we established association with two schools in the hills of Uttarakhand. Our charity will provide
          monetary, Educational & technical support to Shri Timli Vidhyapeeth (Pauri Garhwal) and Motherland Public
          School (Bageshwar - Kumaon Region).</p>

        <p>UWAUK representatives attended Uttarakhand Tourism Evening event organised by Uttarakhand Tourism Ministry to
          explore any new possibilities for tie up with the Government of Uttarakhand. As UWAUK members are located all
          over
          United kingdom so this can be a win win situation for both UWAUK and Government of Uttarakhand. The High
          Commission
          of India have also added us to their contact list so that we are invited to all Cultural programmes held from
          time
          to time.</p>
      </div>
    </div>
  </div>
</section>

<section class="gallery">
  <div class="container-fluid">
    <h1>Gallery</h1>
  </div>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let image of gallery"><img src={{image}} /> </div>
    </div>
  </div>
</section>

<div class="container fblink">
  <p>If you are in UK, Please feel free to join Uttarakhand Community in UK<br>
    <a routerLink="/register">Join UWAUK</a>
  </p>
</div>

<div class="modal fade" id="eventStaticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="eventStaticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="eventStaticBackdropLabel" *ngIf="activeEvent && activeEvent.title">
          {{activeEvent.title}} Online Booking</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center" *ngIf="activeEvent">
        <h5>{{activeEvent.messageTop}}</h5>
        <img style="height:350px;" src={{eventFlyer}} />
        <h5></h5>
        <h5>{{activeEvent.messageBottom}}</h5>
      </div>
      <div class="modal-footer" style="justify-content: center;" *ngIf="activeEvent">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="bookTickets()"
          [disabled]="!activeEvent.isOnlineBooking">{{buttonTitle}}</button>
        <hr />
        <p class="text-center text-md-left">Designed By:
          <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
        </p>
      </div>
    </div>
  </div>
</div>