<div class="container-fluid contactus">
    <section class="contact" id="contact">
        <div class="social">            
            <a href="https://www.facebook.com/uwauk1"><i class="fa fa-facebook"></i></a>
            <a href="#"><i class="fa fa-twitter"></i></a>
            <a href="#"><i class="fa fa-instagram"></i></a>
        </div>
        <div class="contact-us">
            <div class="c-heading">
                <h1>Get in Touch</h1>
                <h4>Our Address</h4>
                <p>28 Jenkins Avenue,</p>
                <p>Bricket Wood,</p>
                <p>St. Albans, Hertfordshire,</p>
                <p>United Kingdom, AL2 3SB</p>
            </div>
            <div class="c-mail">                
                <a href="mailto:contact.uwauk@gmail.com">contact.uwauk@gmail.com</a>
            </div><br/>
            <button class="btn btn-secondary" type="button" (click)="openContactForm()">Send Message</button>
        </div>
        
        <div class="map">
            <!-- <iframe
                src="https://maps.google.com/maps?q=70%20Blandford%20Road%20South,%20SL3%207RY&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="650" height="550" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0">
            </iframe> -->
            <iframe 
            src="https://maps.google.com/maps?q=28%20Jenkins%20Avenue,%20Bricket%20Wood,%20St.%20Albans%20AL23SB&t=&z=13&ie=UTF8&iwloc=&output=embed" 
            width="650" height="550" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0">    
            </iframe>
        </div>
    </section>
</div>

<div class="modal fade" id="contactBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary" id="staticBackdropLabel">Contact Form</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="form-group">                                        
                        <input type="text" class="form-control" id="name" formControlName="name" required="" [ngClass]="{ 'is-invalid': submitted && f.name.errors}" placeholder="Name*">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required" style="float: right">Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="number" class="form-control" id="phone" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Contact Number*">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required" style="float: right">Phone no. is required</div>
                        </div>
                    </div>           
                    <div class="form-group">                                        
                        <input type="email" class="form-control" id="email" formControlName="email"
                            required="" [ngClass]="{ 'is-invalid': submitted && f.email.errors}" placeholder="Email*">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required" style="float: right">Email is required</div>
                            <div *ngIf="f.email.errors.pattern" style="float: right">Enter valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">                                        
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="9" formControlName="message" placeholder="Message"></textarea>
                    </div>
                </div>
                <div class="modal-footer">                    
                    <button style="text-align: center;" type="submit" class="btn btn-primary btn-sm">Send</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="staticBackdrop"  data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Success</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h6>Your message has been sent successfully. We will get back to you soon.</h6>
        <img style="height:110px; width:150px; " src="assets/images/thanks.png" />        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="container fblink">
    <p>If you are in UK, Please feel free to join Uttarakhand Community in UK<br>
        <a routerLink="/register">Join UWAUK</a>
    </p>
</div>