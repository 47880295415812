<div class="container-fluid">
  <h1>Gallery</h1>  
  <div class="row">
    <div class="col-md-3" *ngFor="let item of gallery; let i = index;">
      <div class="card">
        <div *ngIf="item.isVideo" class="card-body">
          <iframe [src]="safeUrl[i]" frameboarder="0" allowfullscreen></iframe>
        </div>
        <div *ngIf="!item.isVideo" class="card-body">
          <a target="_blank" href="{{item.url}}">
            <img src="assets/images/folder-icon.png" class="card-img-top" alt="...">
          </a>          
        </div>
        <div class="card-title">
          <h4 class="text-center">
            {{item.title}}<br>
            <small>{{item.heldOn}}</small>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>