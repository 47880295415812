<!-- Footer -->
<footer class="footer page-footer font-small mdb-color">
  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">
    <!-- Footer links -->
    <div class="row text-center text-md-left mt-3">
      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"></div>
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <a class="navbar-brand" routerLink="/"><img class="foot-img" height="150" width="150"
            src="assets/images/uwauk-logo.jpg"></a>
      </div>
      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none">
      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"></div>
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h5 class="text-uppercase title-f mb-4 ">Sitemap</h5>
        <p>
          <a href="/donate">Donate</a><br/>
          <a href="/gallery">Gallery</a><br/>
          <a href="/members">Members</a><br/>        
          <a href="/about-us">About Us</a><br/>
          <a href="/contact-us">Contact Us</a><br/>
          <a href="/uttarakhand">Uttarakhand</a><br/>
          <a href="/register">Join UWAUK</a><br/>                    
        </p>
      </div>
      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none">
      <!-- Grid column -->
      <!-- <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"> -->
      <!-- <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"> -->
        <!-- <h5 class="text-uppercase mb-4 title-f">Upcoming Events</h5> -->
        <!-- <p>Online Navratri Pooja</p>
        <p>on 13th October 2021</p>
        <p>7:00 PM - 7:40 PM</p> -->
      <!-- </div> -->
      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none">
      <!-- Grid column -->
      <!-- <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3"> -->
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h5 class="text-uppercase mb-4 title-f">Contact</h5>
        <p>
          <i class="fa fa-home mr-3"></i>
          <a target="_blank" href="https://www.google.com/maps/place/28+Jenkins+Ave,+Bricket+Wood,+St+Albans+AL2+3SB/@51.7092092,-0.3774269,17z/data=!3m1!4b1!4m5!3m4!1s0x48763f8961dedffb:0xd3b9efe5e8545b79!8m2!3d51.7092092!4d-0.3752329">
            28 Jenkins Avenue, <br/>Bricket Wood,<br/>St. Albans, Hertfordshire<br/>United Kingdom, AL2 3SB
          </a>
        </p>
        <p>
          <i class="fa fa-envelope mr-3"></i> 
          <a href="mailto:contact.uwauk@gmail.com">contact.uwauk@gmail.com</a>
        </p>
        <div class="social">
          <a target="_blank" href="https://www.facebook.com/uwauk1"><i class="fa fa-facebook mr-3"></i></a>
          <a target="_blank" href="#"><i class="fa fa-instagram"></i></a>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Footer links -->
    <hr>
    <!-- Grid row -->
    <div class="row d-flex align-items-center">
      <!-- Grid column -->
      <div class="col-md-4">
        <!--Copyright-->
        <p class="text-center text-md-left">Designed By:
          <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
        </p>
      </div>
      <div class="col-md-4">
        <!--Copyright-->
        <p class="text-center">© Copyright: {{dateNow | date: 'yyyy'}} Uttarakhand Welfare Association (UK)</p>
      </div>
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->
</footer>
<!-- Footer -->