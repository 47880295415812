import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './static-pages/home/home.component';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { UttrakhandComponent } from './static-pages/uttrakhand/uttrakhand.component';
import { GalleryComponent } from './static-pages/gallery/gallery.component';
import { MembersComponent } from './static-pages/members/members.component';
import { RegisterComponent } from './static-pages/register/register.component';
import { DonateComponent } from './static-pages/donate/donate.component';
import { SuccessComponent } from './static-pages/success/success.component';
import { CancelComponent } from './static-pages/cancel/cancel.component';
import { MapViewComponent } from './static-pages/map-view/map-view.component';
import { BookTicketsComponent } from './static-pages/book-tickets/book-tickets.component';
import { EventComponent } from './static-pages/event/event.component';
import { EventsComponent } from './static-pages/events/events.component';
import { EventCalendarComponent } from './static-pages/event-calendar/event-calendar.component';

const routes: Routes = [
	{ path: '', component: HomeComponent },
  { path: 'members', component: MembersComponent },  
  { path: 'about-us', component: AboutUsComponent }, 
  { path: 'uttrakhand', component: UttrakhandComponent }, 
  { path: 'gallery', component: GalleryComponent },
  { path: 'book-tickets', component: BookTicketsComponent },
  { path: 'contact-us', component: ContactUsComponent }, 
  { path: 'register', component: RegisterComponent }, 
  { path: 'donate', component: DonateComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'cancel', component: CancelComponent },
  { path: 'uwauk-on-map', component: MapViewComponent},
  { path: 'event', component: EventComponent},
  { path: 'events', component: EventsComponent},
  { path: 'event-calendar', component: EventCalendarComponent},
  { path: 'cms', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }