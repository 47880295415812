import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from '../../../services/uwauk.service';
import { CognitoAuthService } from '../../../services/cognito-auth.service';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUrl = '';
  showHideMenu = false;
  isExistUser = null;
  highlights: any;
  message = '';
  allEvents = [];
  activeEvent: any;

  constructor(
    private router: Router,
    private service: Service,
    private cognitoAuthService: CognitoAuthService
  ) {
    this.routeEvent(this.router);
  }

  ngOnInit(): void {
    this.getHighlightsToDisplay();    
  }

  getHighlightsToDisplay() {
    this.service.getHighlights().subscribe((res: any) => {
      //console.log("Response: ", res);
      if (res.statusCode === 200 ) {
        this.highlights = JSON.parse(res.body);
        this.highlights = this.highlights.filter(item => item.isActive === true);
        //console.log("Highlights: ", this.highlights);
        this.message = '|| ';
        this.highlights.forEach(element => {
          this.message += element.message;
          this.message += ' || ';      
        });
      }            
    });
  }

  navigateToUrl(url: string) {    
    this.router.navigate([url]);   
    $('#nav-mbl-head').click();
    console.log("url::" + url);
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })
  }

  navigateUrl(url: string){
    this.router.navigate([url]);
    $('#nav-mbl-head').click();
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        //console.log(e.url);
        this.currentUrl = e.url;
      }
    });
  }

  toggleMenu() {
    this.showHideMenu = !this.showHideMenu;
  }

  login() {   
    this.cognitoAuthService.login();    
  }
}