<div class="container-fluid">
    <h1>Event Calendar</h1> 
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col">
            <div class="row" *ngFor="let event of events;">
                <div class="card">
                    <div class="card-body">
                        <h2>{{event.title}}</h2>
                        <div class="row">
                            <div class="col"><h3>{{event.eventDate}}</h3></div>
                            <div class="col"><h3>{{event.eventTime}}</h3></div>
                        </div>
                        <h4>{{event.venue}}</h4>
                        <h4>{{event.comments}}</h4>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>