import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Service } from '../../services/uwauk.service';
import { loadStripe } from '@stripe/stripe-js';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-book-tickets',
  templateUrl: './book-tickets.component.html',
  styleUrls: ['./book-tickets.component.scss']
})
export class BookTicketsComponent implements OnInit {
  bookTicketForm: FormGroup;
  submitted = false;
  isValid = true;
  isEarlyBird = false;
  
  activeEvent: any;
  messageTitle = ""; 
  refrence: string = ""; 

  adult = {
    number: 0,
    total: 0,
    earlyBirdPrice: 0
  };
  child = {
    number: 0,
    total: 0,
    earlyBirdPrice: 0
  };
  baby = {
    number: 0
  };
  totalAmount = 0; 
  earlyBirdDiscount = 0; 

  constructor(
    private router: Router,
    private service: Service,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.bookTicketForm = this.formBuilder.group({      
      name: ['', Validators.required],
      countryCode: ['+44'],
      contactNumber: ['', Validators.required],
    });

    this.activeEvent = JSON.parse(localStorage.getItem("activeEvent"));
    if (this.activeEvent !== null) {
      if (this.activeEvent.isActive) {    
        if (this.activeEvent.freeEvent) {
          this.messageTitle = "Online Booking is not required for this event.";
          $('#bookingStaticBackdrop').modal('show'); 
        } else if (!this.activeEvent.isOnlineBooking) {
          this.messageTitle = "Online Booking will available soon.";
          $('#bookingStaticBackdrop').modal('show');         
        }        
      } else {
        this.messageTitle = "No Active Event";
        $('#bookingStaticBackdrop').modal('show');      
      }
    } else {
      this.messageTitle = "No Active Event";
      $('#bookingStaticBackdrop').modal('show');      
    }      
  }

  goBackToHome() {
    this.router.navigate(['/']);
  }

  get f() { return this.bookTicketForm.controls; }

  increaseAdult() {
    this.adult.number += 1;  
    this.adult.total = this.adult.number * this.activeEvent.adultPrice;    
    if (this.activeEvent.isEarlyBird) {
      this.earlyBirdDiscount += (this.activeEvent.adultPrice - this.activeEvent.earlyBirdAduldPrice); 
    }   
    this.totalAmount = this.adult.total + this.child.total - this.earlyBirdDiscount;
  }

  decreaseAdult() {
    this.adult.number -= 1;
    this.adult.total = this.adult.number * this.activeEvent.adultPrice;      
    if (this.activeEvent.isEarlyBird) {
      this.earlyBirdDiscount -= (this.activeEvent.adultPrice - this.activeEvent.earlyBirdAduldPrice); 
    }
    this.totalAmount = this.adult.total + this.child.total - this.earlyBirdDiscount;

    if (this.adult.number === 0) {
      this.child.number = 0;
      this.child.total = 0;
      this.baby.number = 0;
      this.totalAmount = 0;
      this.earlyBirdDiscount = 0;
    }
  }

  increaseChild() {
    this.child.number += 1;   
    this.child.total = this.child.number * this.activeEvent.childPrice;     
    if (this.activeEvent.isEarlyBird) {
      this.earlyBirdDiscount += (this.activeEvent.childPrice - this.activeEvent.earlyBirdChildPrice);
    }        
    this.totalAmount = this.adult.total + this.child.total - this.earlyBirdDiscount;
  }

  decreaseChild() {
    this.child.number -= 1;
    this.child.total = this.child.number * this.activeEvent.childPrice;         
    if (this.activeEvent.isEarlyBird) {
      this.earlyBirdDiscount -= (this.activeEvent.childPrice - this.activeEvent.earlyBirdChildPrice);  
    }
    this.totalAmount = this.adult.total + this.child.total - this.earlyBirdDiscount;
  }

  increaseBaby() {
    this.baby.number += 1;    
  }

  decreaseBaby() {
    this.baby.number -= 1;
  }

  proceedToPay() {
    this.submitted = true;
    if (this.bookTicketForm.invalid) {
      return;
    }
    this.bookTicketForm.value.name = this.capitalize(this.bookTicketForm.value.name);    
    this.setRefrence();
    //console.log(this.bookTicketForm.value);

    this.createSessionRedirectToCheckout();
  }

  async createSessionRedirectToCheckout() {
    const stripeObj = {
      name: "UWAUK " + this.activeEvent.title + " Ticket Booking",
      amount: Math.round(this.totalAmount * 100),
      transactionID: Math.floor(1000000 + Math.random() * 9000000).toString(),
      user: {
        name: this.bookTicketForm.value.name,
        contactNumber: this.bookTicketForm.value.contactNumber,
        adult: this.adult.number,
        child: this.child.number,
        baby: this.baby.number
      },
      payeeRef: this.refrence,
      eventID: this.activeEvent.eventID,
      eventRef: this.activeEvent.eventRef
    };
    //const stripe = await loadStripe('pk_test_51JhZlBDnD4VkFd1SiDB2mFztwJA8djHFYXjC6cxEzQe461jZbzXMoaKKRjJryAlthaP5MicXlwaF5REVtg0FIrXr005jClyEka');    
    const stripe = await loadStripe('pk_live_51JhZlBDnD4VkFd1S3PrVExnNOe3qn4UV7CD19ekEiU30390DIWSvNflY6Hiqw7uQgAYgCWuzeg4ftM6kZu2k8AtY00yvVOsi3M');
    this.service.createSession(stripeObj).subscribe((session: any) => {
      //console.log('res: ', session);      
      //console.log('Session Id: ', session.id);      
      stripe.redirectToCheckout({      
        sessionId: session.id,
      });
    });    
  }

  check(contactNumber) {
    //console.log('Input Contact Number: ', contactNumber);
    if (contactNumber.charAt(0) === '0'){
      contactNumber = contactNumber.slice(1);
    }
    contactNumber = this.bookTicketForm.value.countryCode + contactNumber;
    //console.log('Formatted Contact Number: ', contactNumber);
    if (contactNumber.length !== 13) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
    this.bookTicketForm.value.contactNumber = contactNumber;
  }

  capitalize(nameString) {    
    if (typeof nameString !== 'string') return '';
    var nameArr = nameString.split(" ");  
    //console.log('Name: ', nameString);
    nameString = '';  
    nameArr.forEach(name => {
      name = name.toLowerCase(); 
      name = name.charAt(0).toUpperCase() + name.slice(1);
      nameString += name + ' ';
    });
    nameString = nameString.trim();
    //console.log('Formatted Name: ', nameString);
    return nameString;
  }

  setRefrence() {
    this.refrence = this.activeEvent.eventRef + '-' + this.bookTicketForm.value.name.split(' ').join('-');
    if (this.adult.number > 0) {
      this.refrence += '-' + this.adult.number + 'A';
      if (this.child.number > 0) {
        this.refrence += '-' + this.child.number + 'C';
      }
      if (this.baby.number > 0) {
        this.refrence += '-' + this.baby.number + 'B';
      }
    } 
    //console.log('Refrence: ', this.refrence);
  }
}
