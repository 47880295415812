import { Component, OnInit } from '@angular/core';
import { Service } from '../../services/uwauk.service';
import { loadStripe } from '@stripe/stripe-js';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  submitted = false;
  donationForm: FormGroup;
  ok = true;
  amount = 0;
  refrence: any;

  constructor(
    private service: Service,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {    
    this.donationForm = this.formBuilder.group({
      amount: [ ,Validators.required],
      payeeRef: ['']
    });
  }

  get f() { return this.donationForm.controls; }
  
  donate() {
    this.submitted = true;
    if (this.donationForm.invalid) {
      //console.log('Main Form is invalid');  
      return;
    }
    this.createSessionRedirectToCheckout();
  }

  getAmount(amount) {
    //console.log("Amount to pay: ", amount);
    if (amount > 0) {
      this.amount = Math.round(amount * 100);
      this.ok = true;
    } else {
      this.ok = false;
    }     
  }

  getRef(refrence) {
    //console.log('Ref: ', refrence);
    if (refrence === '')
      this.refrence = 'Donation';
    else
      this.refrence = refrence;
  }

  async createSessionRedirectToCheckout() {
    const stripeObj = {
      name: "UWAUK Donation Page",
      amount: this.amount,
      transactionID: Math.floor(1000000 + Math.random() * 9000000).toString(),
      payeeRef: this.refrence
    };
    //const stripe = await loadStripe('pk_test_51JhZlBDnD4VkFd1SiDB2mFztwJA8djHFYXjC6cxEzQe461jZbzXMoaKKRjJryAlthaP5MicXlwaF5REVtg0FIrXr005jClyEka');    
    const stripe = await loadStripe('pk_live_51JhZlBDnD4VkFd1S3PrVExnNOe3qn4UV7CD19ekEiU30390DIWSvNflY6Hiqw7uQgAYgCWuzeg4ftM6kZu2k8AtY00yvVOsi3M');
    this.service.createSession(stripeObj).subscribe((session: any) => {
      //console.log('res: ', session);      
      //console.log('Session Id: ', session.id);      
      stripe.redirectToCheckout({      
        sessionId: session.id,
      });
    });    
  }
}