import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from '../../services/uwauk.service';
import { environment } from 'src/environments/environment';
declare var Swiper: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  Swiper: any;

  isActive = false;
  freeEvent = false;
  activeEvent: any; 
  buttonTitle = "Online Booking is Available Now";  
  s3BaseURL = `${environment.s3BaseUrl}`;  
  eventFlyer = this.s3BaseURL + "event-flyer.jpeg";
  eventBanner = this.s3BaseURL + "event-banner.jpeg";
  home1 = this.s3BaseURL + "home-01.jpeg";
  home2 = this.s3BaseURL + "home-02.jpeg";
  home3 = this.s3BaseURL + "home-03.jpeg";
  home4 = this.s3BaseURL + "home-04.jpeg";
  
  constructor(
    private router: Router,
    private service: Service
  ) { }

  ngOnInit(): void {
    this.Swiper = new Swiper('.swiper-container', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 20,
        stretch: 0,
        depth: 200,
        modifier: 1,
        slideShadows: true,
      },
     loop: true,
     autoplay:{
       delay: 1000,
       disableOnInteraction: false,
     }
    });

    this.getEvent();
  }

  getEvent() {
    this.service.getEvents().subscribe((res: any) => {
      if (res.statusCode === 200 ) {
        var allEvents = JSON.parse(res.body);  
        allEvents = allEvents.filter(a => a.isActive);
        console.log("allEvents: ", allEvents);
        this.activeEvent = allEvents.length == 0 ? null : allEvents[0];
        console.log("events Details: ", this.activeEvent); 

        if (this.activeEvent !== null) {
          this.isActive = this.activeEvent.isActive;
          this.freeEvent = this.activeEvent.freeEvent;
          const today = new Date().getTime();        
            const eventDate = new Date(this.activeEvent.eventDate).getTime();
            const days = (eventDate - today)/(1000*60*60*24);
            console.log("Days = ", days);
            if (this.activeEvent.isActive && days > 0) {   
              localStorage.setItem("activeEvent", JSON.stringify(this.activeEvent));
              if (this.activeEvent.isOnlineBooking) {
                this.buttonTitle = "Online Booking is Available Now";        
              } else {
                this.buttonTitle = "Online Booking has been Closed Now";
              }
              if (!this.activeEvent.freeEvent) {
                $('#eventStaticBackdrop').modal('show');
              }
            }
        } else {
          localStorage.removeItem("activeEvent");
        }             
      }            
    });
  }

  public slides = [        
    { img: this.s3BaseURL + "banner-01.jpeg" },
    { img: this.s3BaseURL + "banner-02.jpeg" },
    { img: this.s3BaseURL + "banner-03.jpeg" },
    { img: this.s3BaseURL + "banner-04.jpeg" },
    { img: this.s3BaseURL + "banner-05.jpeg" },
    { img: this.s3BaseURL + "banner-06.jpeg" },
    { img: this.s3BaseURL + "banner-07.jpeg" },
    { img: this.s3BaseURL + "banner-08.jpeg" },
    { img: this.s3BaseURL + "banner-09.jpeg" },
    { img: this.s3BaseURL + "banner-10.jpeg" },
    { img: this.s3BaseURL + "banner-11.jpeg" }
  ];

  public gallery = [
    this.s3BaseURL + "gallery-01.jpeg",
    this.s3BaseURL + "gallery-02.jpeg",
    this.s3BaseURL + "gallery-03.jpeg",
    this.s3BaseURL + "gallery-04.jpeg",
    this.s3BaseURL + "gallery-05.jpeg",
    this.s3BaseURL + "gallery-06.jpeg",
    this.s3BaseURL + "gallery-07.jpeg",
    this.s3BaseURL + "gallery-08.jpeg",
    this.s3BaseURL + "gallery-09.jpeg",
    this.s3BaseURL + "gallery-10.jpeg",
    this.s3BaseURL + "gallery-11.jpeg",
    this.s3BaseURL + "gallery-12.jpeg",
    this.s3BaseURL + "gallery-13.jpeg",
    this.s3BaseURL + "gallery-14.jpeg",
    this.s3BaseURL + "gallery-15.jpeg",
  ];

  public slideConfig = {
    infinite: true,
    speed: 600,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  bookTickets() {
    this.router.navigate(['/book-tickets']);
  }
}