import { Component, OnInit } from '@angular/core';
import { Service } from '../../services/uwauk.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  
  constructor(
    private service: Service,
  ) { }

  ngOnInit(): void {
    var params = new URLSearchParams(window.location.search);    
    //console.log('sessionId: ', params.get('id'));

    // this.service.retrieveSession(params.get('id')).subscribe((session: any) => {
    //   console.log('Session: ', session);
    // });
  }
}