export const environment = {
  production: false,

  webAPIBaseURL: 'https://53j9ro0dxf.execute-api.eu-west-2.amazonaws.com/prod',
  stripeBaseURL: 'https://snbc7o9jdb.execute-api.eu-west-2.amazonaws.com/prod',
  apiBaseURL: 'https://d0y7ljkoa8.execute-api.eu-west-2.amazonaws.com/prod/',
  apiForS3URL: 'https://j186qagv10.execute-api.eu-west-2.amazonaws.com/prod/uwauk-members/',
    
  registerAPIUrl: 'https://ypzwie5k9i.execute-api.eu-west-2.amazonaws.com/prod/register',
  sendMessageAPIUrl: 'https://6lz1br7q71.execute-api.eu-west-2.amazonaws.com/prod/sendMessage',  

  s3BaseUrl: "https://j186qagv10.execute-api.eu-west-2.amazonaws.com/prod/uwauk-assets/",
  //****************************** Cognito ********************************/
  poolID: 'eu-west-2_0mvJQrX9x',
  clientID: '44pf3rpifed4s94lhrpfo1bv5r',
  clientSecret: '1jlk1l11rel7u9iff0m4bc11fbchkosq7sl1ubihiioekirejf7o',
  cognitoBaseURL: 'https://uwauk.auth.eu-west-2.amazoncognito.com/',
  redirectURI: 'http://localhost:4200/cms',
  signOutURL: 'http://localhost:4200/',

  //************************************************************************/
};