import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Service } from '../../services/uwauk.service';
import { CmsService } from '../../services/cms.service';
import { HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-events',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  allEvents = [];
  eventForm: FormGroup;
  loading = false;
  submitted = false;
  updateData: any;
  objToDelete: any;
  addOrEdit = false;
  isReadOnly = false;
  param: any;
  userInfo: any;

  constructor(
    private service: Service,
    private cmsService: CmsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.eventForm = this.formBuilder.group({
      title: ['', Validators.required],      
      eventDate: ['', [Validators.required]],
      venue: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      adultPrice: [0, Validators.required],
      childPrice: [0, Validators.required],
      ticketStartFrom: [101, Validators.required],
      messageTop: ['', Validators.required],
      messageBottom: ['', Validators.required],
      earlyBirdPriceDate: ['', [Validators.required]],
      earlyBirdAduldPrice: [0, Validators.required],
      earlyBirdChildPrice: [0, Validators.required],
      isOnlineBooking: [false]
    });
    this.getAllEvents();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }

  get f() { return this.eventForm.controls; }

  getAllEvents() {
    this.allEvents = [];    
    this.service.getEvents().subscribe((res: any) => {
      if (res.statusCode === 200 ) {
        this.allEvents = JSON.parse(res.body);
        //console.log("events Details: ", this.events);        
      }            
    });
  }
  
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.eventForm.invalid) {
      return;
    }
    this.param = this.eventForm.value;
    this.param.name = this.userInfo.given_name + " " + this.userInfo.family_name;
    if ('eventID' in this.updateData) {
      this.addOrEdit = false;
      this.param.eventID = this.updateData.eventID;
    }
    this.spinner.show();
    this.cmsService.addEvent(this.param).subscribe((data: any) => {
      //console.log("After adding: ", data);
      if (data && data.statusCode === 200) {
        this.eventForm.reset();
        this.submitted = false;
        this.spinner.hide();
        if (this.addOrEdit) {
          this.toastr.success('event Added');
        } else {
          this.toastr.success('event Updated');
        }
        this.getAllEvents();
        $('#staticBackdrop').modal('hide');
      } else {
        //console.log('error in Add/Update  ');
        this.submitted = false;
        this.spinner.hide();
        this.toastr.error('Something went wrong.', 'Oops!');
        $('#staticBackdrop').modal('hide');
      }
    }, (error: HttpErrorResponse) => {
      this.submitted = false;
      this.spinner.hide();
      this.toastr.error('Something went wrong.', 'Oops!');
      $('#staticBackdrop').modal('hide');
    });
  }

  addRow() {
    this.addOrEdit = true;
    this.isReadOnly = false;
    this.eventForm.reset();
    this.eventForm.patchValue({       
      ticketStartFrom: 101,
      venue: "Blue Room, Mumbai Gardens, Harrow, HA26LY",
      isOnlineBooking: false });
    this.updateData = {}
    this.submitted = false;
    $('#staticBackdrop').modal('show');
  }

  editRow(rowData: any) {
    this.addOrEdit = false;
    this.isReadOnly = !rowData.isActive;
    $('#staticBackdrop').modal('show');    
    this.updateData = rowData;
    //console.log('Edit Form: ', rowData);
    this.eventForm.patchValue({
      title: rowData.title,
      eventDate: new Date(rowData.eventDate).toJSON().split('T')[0],
      venue: rowData.venue,
      startTime: this.convertTime12to24(rowData.startTime),
      endTime: this.convertTime12to24(rowData.endTime),
      adultPrice: rowData.adultPrice,
      childPrice: rowData.childPrice,
      ticketStartFrom: rowData.ticketStartFrom,
      messageTop: rowData.messageTop,
      messageBottom: rowData.messageBottom,
      earlyBirdPriceDate: new Date(rowData.earlyBirdPriceDate).toJSON().split('T')[0],
      earlyBirdAdultPrice: rowData.earlyBirdAdultPrice, 
      earlyBirdChildPrice: rowData.earlyBirdChildPrice,      
      isOnlineBooking: rowData.isOnlineBooking 
    });
  }

  confirmDeletion(data: any) {
    $('#confirmationPopup').modal('show');
    this.objToDelete = data;
  }

  toggleSwitch(event, index: number, rowData: any) {
    this.allEvents[index].isOnlineBooking = !this.allEvents[index].isOnlineBooking;
    this.spinner.show();
    this.cmsService.addEvent({
      id: rowData.id,
      title: rowData.title,
      eventDate: rowData.eventDate,
      venue: rowData.venue,
      startTime: rowData.startTime,
      endTime: rowData.endTime,
      adultPrice: rowData.adultPrice,
      childPrice: rowData.childPrice,
      ticketStartFrom: rowData.ticketStartFrom,
      messageTop: rowData.messageTop,
      messageBottom: rowData.messageBottom,
      earlyBirdPriceDate: rowData.earlyBirdPriceDate,
      earlyBirdAdultPrice: rowData.earlyBirdAdultPrice, 
      earlyBirdChildPrice: rowData.earlyBirdChildPrice,        
      isOnlineBooking: this.allEvents[index].isOnlineBooking,
      name: this.userInfo.given_name + " " + this.userInfo.family_name    
    }).subscribe((data: any) => {
      this.spinner.hide();
      this.getAllEvents();
      this.toastr.success('Status Changed Successfully', 'Success');
    }, (error: HttpErrorResponse) => {
      this.toastr.error('Something went wrong.', 'Oops!');
      this.spinner.hide();
    });
  }

  convertTime12to24(time12h) {
    //console.log('Time12: ', time12h);
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    //console.log('Time24: ', `${hours}:${minutes}`);
    return `${hours}:${minutes}`;
  }
}
