<div class="container mt-4 mb-4">
    <div class="row mt-4">
        <div class="col-md-3"></div>
        <div class="col-md-6 mt-4 mb-4 bg-white book-ticket" *ngIf="activeEvent">
            <h1 class="mt-3 mb-2">{{activeEvent.title}}</h1>
            <hr />
            <h3>{{activeEvent.eventDate | date : 'EEE, dd MMM yyyy'}} From {{activeEvent.startTime}} to {{activeEvent.endTime}}</h3>
            <h3>{{activeEvent.venue}}</h3>                        
            <hr/>
            <h2>Book Your Tickets</h2>
            <p>Online Booking Available till: {{activeEvent.ticketClosingDate | date : 'EEE, dd MMM yyyy'}}</p>
            <h2 *ngIf="activeEvent.isEarlyBird" style="color: red;">Early Bird Prices: Adult: {{activeEvent.earlyBirdAduldPrice | currency:'GBP'}} Child: {{activeEvent.earlyBirdChildPrice | currency:'GBP'}}</h2>
            <p *ngIf="activeEvent.isEarlyBird" style="color: red;">Applicable till {{activeEvent.earlyBirdPriceDate | date : 'EEE, dd MMM yyyy'}}</p>
            <br />            
            <form [formGroup]="bookTicketForm">
                <div class="form-row">
                    <div class="form-group col">
                        <input type="text" formControlName="name" class="form-control" autocomplete="off"                           
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Full Name*" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Please enter your Name.</div>
                        </div>
                    </div>                    
                </div> 
                <div class="form-row">
                    <div class="form-group col-2">
                        <input [readonly]="true" type="text" formControlName="countryCode" class="form-control" value="+44"/>                        
                    </div>
                    <div class="form-group col">
                        <input type="number" formControlName="contactNumber" class="form-control" (change)="check($event.target.value)"
                            [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" placeholder="Contact Number*" />
                        <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.contactNumber.errors.required">Please enter contact number.</div>                            
                        </div>
                        <div *ngIf="!isValid" class="text-danger">Invalid contact number.</div>
                    </div>
                </div>  
                <div>
                    <table class="table table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-center" style="width: 20%">Price</th>
                                <th class="text-center" style="width: 30%">No of Peoples</th>
                                <th class="text-center" style="width: 20%">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Adult</th>
                                <td class="text-center">{{activeEvent.adultPrice | currency:'GBP'}}</td>
                                <td class="text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <button type="button" class="btn btn-sm" [disabled]="adult.number === 0" (click)="decreaseAdult()"><i class="fa fa-minus-circle"></i></button>
                                            </td>
                                            <td>{{adult.number}}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm" (click)="increaseAdult()"><i class="fa fa-plus-circle"></i></button>
                                            </td>
                                        </tr>
                                    </table>                                    
                                </td>
                                <td class="text-center" style="width: 10%">{{ adult.total | currency:'GBP' }}</td>
                            </tr>
                            <tr>
                                <th>Child (5 - 10)</th>                                
                                <td class="text-center">{{activeEvent.childPrice | currency:'GBP'}}</td>
                                <td class="text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <button type="button" class="btn btn-sm" [disabled]="child.number === 0" (click)="decreaseChild()"><i class="fa fa-minus-circle"></i></button>
                                            </td>
                                            <td>{{child.number}}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm" [disabled]="adult.number === 0" (click)="increaseChild()"><i class="fa fa-plus-circle"></i></button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="text-center" style="width: 10%">{{ child.total | currency:'GBP' }}</td>
                            </tr>
                            <tr>
                                <th>Child (Under 5)</th>
                                <td class="text-center">Free</td>
                                <td class="text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <button type="button" class="btn btn-sm" [disabled]="baby.number === 0" (click)="decreaseBaby()"><i class="fa fa-minus-circle"></i></button>
                                            </td>
                                            <td>{{baby.number}}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm" [disabled]="adult.number === 0" (click)="increaseBaby()"><i class="fa fa-plus-circle"></i></button>
                                            </td>
                                        </tr>
                                    </table>                                    
                                </td>
                                <td class="text-center" style="width: 10%">Free</td>
                            </tr>
                        </tbody>
                    </table>
                    <li *ngIf="activeEvent.isEarlyBird" class="list-group-item bg-danger">
                        <span class="text-white"><strong>Early Bird Discount</strong>
                          <span style="float: right;">{{ earlyBirdDiscount | currency:'GBP'}}</span>
                        </span>
                    </li>
                    <br/>
                    <li class="list-group-item bg-dark">
                        <span class="text-white"><strong>Total Amount to Pay</strong>
                          <span style="float: right;">{{ totalAmount | currency:'GBP'}}</span>
                        </span>
                    </li>
                </div>      
                <br/>       
                <div class="text-center">
                    <button class="btn btn-primary mr-1" [disabled]="totalAmount === 0" (click)="proceedToPay()">Proceed to Pay</button>                    
                    <br />
                    <hr />
                    <p class="text-center">Designed By:
                        <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
                    </p>
                </div>
                <br />
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="bookingStaticBackdrop"  data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="bookingStaticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-danger" id="bookingStaticBackdropLabel">Booking Closed</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="goBackToHome()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">        
        <h5>{{messageTitle}}</h5>       
        <h6>Please be in touch with Event team for any further information. Thank You</h6>
      </div>
      <div class="modal-footer" style="justify-content: center;">
        <button type="button" class="btn btn-danger" style="width: 50%;" data-dismiss="modal" (click)="goBackToHome()">Close</button>
        <hr/>
        <p class="text-center text-md-left">Designed By:
            <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
        </p>
      </div>
    </div>
  </div>
</div>