<div class="container donate">
    <br/>
    <h1>Uttarakhand Welfare Association (UK)</h1><br/>    
    <h2>Your contribution to UWAUK would be spend on welfare activities for Uttarakhandi community.</h2><br/>
    <form [formGroup]="donationForm">
        <div class="form-row">
            <div class="form-group col"></div>
            <div class="form-group col" >                                         
                <input type="number" formControlName="amount" class="form-control" placeholder="Donation Amount in Pounds" (change)="getAmount($event.target.value)"
                    [ngClass]="{ 'is-invadivd': submitted && f.amount.errors }" style="font-size: 15px; font-weight: 600; text-align: center; width: 350px;"/>
                <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                    <div *ngIf="f.amount.errors.required">Please enter Amount you would like to donate.</div>                                     
                </div>
            </div>
            <div class="form-group col"></div>
        </div>
        <div class="form-row">
            <div class="form-group col"></div>
            <div class="form-group col" >                                         
                <input type="text" formControlName="payeeRef" class="form-control" placeholder="Payee Reference" (change)="getRef($event.target.value)"
                    [ngClass]="{ 'is-invadivd': submitted && f.payeeRef.errors }" style="font-size: 15px; font-weight: 600; text-align: center; width: 350px;"/>
            </div>
            <div class="form-group col"></div>
        </div>        
        <div class="text-center">            
            <button class="btn btn-success" [disabled]="!ok" (click)="donate()">Donate</button>
        </div>     
        <br/>   
    </form>
    <h2>Please feel free to reach us at <a href="mailto:contact.uwauk@gmail.com">contact.uwauk@gmail.com</a> to set up regular donation.</h2>
</div>